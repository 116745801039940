import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import projectsData from './util/ProjectData'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import FullScreenShadowModal from './FullScreenModal';
function ProjectPage() {
  const { projectId } = useParams();

  const { t, i18n } = useTranslation();
  const myProject = projectsData.find(proj => proj.redirect === projectId);

  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedImage(null);
  };

  return (
    <>
    <Helmet>
      <title>Spatial Architecture </title>
      <meta
        name="description"
        content="Discover the cutting-edge designs of Spatial Architecture, blending innovation with practicality. Our expertise in sustainable architecture and modern design transforms spaces into functional works of art."
        />
      <meta property="og:title" content="Spatial Architecture" />
      <meta
        property="og:description"
        content="Discover the cutting-edge designs of Spatial Architecture, blending innovation with practicality. Our expertise in sustainable architecture and modern design transforms spaces into functional works of art."
        />
      <meta
        property="og:image"
        content="https://github.com/gdimitropoulos/public-photos/blob/main/table.png"
      />
      <meta property="og:url" content="https://spatialarchitecture.gr/" />
    </Helmet>
    <Container className="flex-grow-1" style={{   display: 'flex' , flexDirection:'column' , justifyContent: 'center',textAlign: 'justify' }}>
      <div className='row'  style={{ margin: 20}} >
        <div className='col-12' >

            <h3 className='mb-4 mt-5'> { t(myProject.title)}</h3>
            {myProject.location &&  <h6>  { t(myProject.location)} </h6> } 

            {myProject.info &&  <h6>  { t(myProject.info)} </h6> } 

            {myProject.photoBy &&  <h6> {t('photography')}: { t(myProject.photoBy)} </h6> } 
            {myProject.description.map((paragraph, index) => (
            <p className='descr-text mt-3' key={index} >{t(paragraph)}</p>
      ))}

      

        </div>


        <Row  >
            {myProject.images.map((image, index) => (
              <Col xs={12} md={6} lg={4} className="mb-4 " key={index}>
                    <img
                      src={image.src}
                      alt={image.title}
                      onClick={() => handleImageClick(image)} 
                      className="project-image"
                    />

              </Col>
            ))}
          </Row>

      </div>
    </Container>
    <FullScreenShadowModal
      showModal={showModal}
      onClose={handleCloseModal}
      selectedImage={selectedImage}
      images={myProject.images} 
    />
  </>
  );
}

export default ProjectPage;