import React from 'react';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import '@fortawesome/fontawesome-svg-core/styles.css'
import { useTranslation } from 'react-i18next';


function Footer() {

  const { t } = useTranslation();


  return (
    <footer className="" style={{ fontSize: 13,  minHeight: 100,   backgroundColor: 'black'   }} >
      <Container className='justify-content-between mt-3' style={{}} >
        <div className="d-flex flex-wrap justify-content-between ">
          <div className="col-md-4"> {/* First grid column (copyright + emails) */}

            <ul className="list-unstyled" >
              <li>

                <p className="text-white">
                  Copyright © 2024 Spatial Architecture Office
                </p>

              </li>

              <li className="mb-1">
                <a href={`mailto:info@spatialarchitecture.gr`} className="text-white">
                  info@spatialarchitecture.gr
                </a>
              </li>
              
            </ul>
          </div>
          <div className="col-md-4"> {/* Second grid column (address) */}
            <p className="text-center text-white">
            {   t('loc')}            </p>

            <p className="text-center text-white">
              +30  210 725 7416
            </p>

          </div>
          <div className="col-md-4 d-flex justify-content-end"> {/* Third grid column (social icons) */}
            <a style={{ fontSize: 30}} rel="noreferrer" href="https://www.instagram.com/spatial_architecture_/?igsh=MTZ4Nm5hanc3d2VqMQ%3D%3D" target="_blank" className="me-3 text-white">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a style={{ marginLeft: 10 , fontSize: 30}} rel="noreferrer" target="_blank"  href="https://www.facebook.com/profile.php?id=61558936798690&rdid=dKwLgw9ragrGE8Gi&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FzSbPWsGF1uHyTfDf" className="me-3 text-white">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a style={{ marginLeft: 10 , fontSize: 30}} rel="noreferrer"   target="_blank" href=" https://www.linkedin.com/company/102905619/admin/notifications/all/" className="text-white">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
        </div>


      </Container>
    </footer>
  );
}

export default Footer;
