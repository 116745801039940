import React, { useState } from 'react'; // Import useState and useRef hooks
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import '../customCssSwiper.css'


const ArchitectureSlider = ({ images }) => {
  const [swiperRef, setSwiperRef] = useState();
  const navigate = useNavigate();

  const RedirectToProjectPage = (image) => {
    if (image.clickable) {
      navigate(`/project/${image.redirect}`);
    }

  }
  return (
    <>
{/* In commit "final_changes_kappa_kippo" 30/07/24 there are all the previously implemented logics including the buttons for swipper */}

      <Swiper
        style={{  height: '350px', position: 'relative'}}
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={10} 
        slidesPerView={2}    
        onSwiper={ setSwiperRef}
        loop
        onSlideChange={(swiper) => {
          swiper.navigation.destroy()
        }}
        breakpoints={{
          0: { 
            slidesPerView: 1,
          },
          768: { 
            slidesPerView: 2,
          }
        }}
        pagination={{
          clickable: true,
          renderBullet: (index,className) => {
            return `<span  class="${className}  "> <div class="dot"> </div> </span>`;
          },
        }}
      >
        {/*Swiper slides */}
        {images.map((image, index) => (
          <SwiperSlide key={index} onClick={() => RedirectToProjectPage(image)} >
            <img src={image.src} alt={image.alt} />
            <div className="slide-container">

              <div className="image-overlay custom-pointer">
                <div className="overlay-content"> 
                  <span>{image.name}</span>
                  <br />
                  {image.caption && (
                    <p className='image-capt'> {image.caption}</p>
                  )}
                </div>
              </div>
            </div>


          </SwiperSlide>
        ))}
 
      </Swiper>
    </>
  );

};

export default ArchitectureSlider;
