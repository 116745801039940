import React from "react";
import { Container } from "react-bootstrap";
import ArchitectureSlider from "./SwiperCarousel";
import { Helmet } from "react-helmet";

function Dashboard() {


  const images = [
  
    {
      src: "spacial_office.jpg",
      alt: "SP10_Office",
      name: "SP10_Office",
      redirect: "SP10_Office",
      clickable: true
    },
    {
      src: "/SITE_PROJECTS/03_COMMERCIAL/02_CINE_PARIS/COLLAGES/COLLAGE1.jpg",
      alt: "Cine_Paris",
      name: "Cine Paris",
      redirect: "Cine_Paris",
      clickable: true
    },
    {
      src: "/SITE_PROJECTS/01_RESIDENTIAL/02_VILLA_WING/VILLA_WING_1.jpg",
      alt: "Villa_wing",
      name: " Villa Wing",
      redirect: "Villa_wing",
      clickable: true
    },
    {
      src: "/SITE_PROJECTS/02_HOSPITALITY/02_OLD_TOWN_BOUTIQUE_HOTEL/OLD_TOWN_1.jpg",
      alt: "Old Town Boutique Hotel",
      name: "Old Town Boutique Hotel",
      redirect: "OLD_TOWN",
      clickable: true
    },

    {
      src: "/SITE_PROJECTS/03_COMMERCIAL/04_HEARTCORE_GYM/HEARTCORE_2.jpg",
      alt: "Pilates studio",
      name: "Pilates studio",
      redirect: "pilates_studio",
      clickable: true
    },
    {
      src: "/SITE_PROJECTS/01_RESIDENTIAL/03_VILLA_MIES/VILLA_MIES_1.jpg",
      alt: "Villa Mies",
      name: "Villa Mies",
      redirect: "Villa_mies",
      clickable: true
    },
    {
      src: "/SITE_PROJECTS/01_RESIDENTIAL/05_VILLA_KORAKIANA/VILLA_KORAKIANA_1.jpg",
      alt: "Korakiana",
      name: "Korakiana",
      redirect: "Villa_korakiana",
      clickable: true
    },
  ];

  return (
    <>
      <Helmet>
        <title>Spatial Architecture </title>
        <meta
          name="description"
          content="Discover the cutting-edge designs of Spatial Architecture, blending innovation with practicality. Our expertise in sustainable architecture and modern design transforms spaces into functional works of art."
          />
        <meta property="og:title" content="Spatial Architecture" />
        <meta
          property="og:description"
          content="Discover the cutting-edge designs of Spatial Architecture, blending innovation with practicality. Our expertise in sustainable architecture and modern design transforms spaces into functional works of art."
          />
        <meta
          property="og:image"
          content="https://github.com/gdimitropoulos/public-photos/blob/main/table.png"
        />
        <meta property="og:url" content="https://spatialarchitecture.gr/" />
      </Helmet>
      <Container className="flex-grow-1" style={{   display: 'flex' , flexDirection:'column' , justifyContent: 'center'}}>
        <div  style={{ marginLeft: 20 , marginRight: 20}}>
          <ArchitectureSlider   images={images} />
        </div>
      </Container>
    </>
  );
}

export default Dashboard;
