const projectsData = [
  {
    clickable: true,
    src: "spacial_office.jpg",
    title: "SP10_Office",
    redirect: "SP10_Office",
    description: [
      `project_office` ,
    ],
    category: 'Commercial',
    location: 'Kolonaki_athens',
    photoBy: `myrto`,
    images: [
      { src: "/SITE_PROJECTS/03_COMMERCIAL/01_SP_OFFICE/00.jpg", title: "sp_office" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/01_SP_OFFICE/01.jpg", title: "sp_office" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/01_SP_OFFICE/02.jpg", title: "sp_office" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/01_SP_OFFICE/03.jpg", title: "sp_office" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/01_SP_OFFICE/04.jpg", title: "sp_office" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/01_SP_OFFICE/05.jpg", title: "sp_office" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/01_SP_OFFICE/06.jpg", title: "sp_office" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/01_SP_OFFICE/07.jpg", title: "sp_office" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/01_SP_OFFICE/08.jpg", title: "sp_office" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/01_SP_OFFICE/09.jpg", title: "sp_office" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/01_SP_OFFICE/10.jpg", title: "sp_office" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/01_SP_OFFICE/11.jpg", title: "sp_office" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/01_SP_OFFICE/12.jpg", title: "sp_office" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/01_SP_OFFICE/13.jpg", title: "sp_office" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/01_SP_OFFICE/14.jpg", title: "sp_office" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/01_SP_OFFICE/15.jpg", title: "sp_office" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/01_SP_OFFICE/16.jpg", title: "sp_office" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/01_SP_OFFICE/17.jpg", title: "sp_office" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/01_SP_OFFICE/18.jpg", title: "sp_office" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/01_SP_OFFICE/19.jpg", title: "sp_office" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/01_SP_OFFICE/20.jpg", title: "sp_office" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/01_SP_OFFICE/21.jpg", title: "sp_office" },
    ],
  },
  {
    clickable: true,
    src: "cine.png",
    title: "Cine Paris",
    category: 'Commercial',
    description: [
      `project_cine` ,
    ],
    redirect: "Cine_Paris",
    location: "Plaka_athens",
    photoBy: `Spatial`,
    images: [
      { src: "/SITE_PROJECTS/03_COMMERCIAL/02_CINE_PARIS/COLLAGES/COLLAGE1.jpg", title: "Cine Paris" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/02_CINE_PARIS/COLLAGES/COLLAGE2.jpg", title: "Cine Paris" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/02_CINE_PARIS/PHOTOS/1.jpg", title: "Cine Paris" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/02_CINE_PARIS/PHOTOS/2.jpg", title: "Cine Paris" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/02_CINE_PARIS/PHOTOS/3.jpg", title: "Cine Paris" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/02_CINE_PARIS/PHOTOS/4.jpg", title: "Cine Paris" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/02_CINE_PARIS/PHOTOS/5.jpg", title: "Cine Paris" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/02_CINE_PARIS/PHOTOS/6.jpg", title: "Cine Paris" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/02_CINE_PARIS/PHOTOS/7.jpg", title: "Cine Paris" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/02_CINE_PARIS/PHOTOS/8.jpg", title: "Cine Paris" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/02_CINE_PARIS/PHOTOS/9.jpg", title: "Cine Paris" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/02_CINE_PARIS/PHOTOS/10.jpg", title: "Cine Paris" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/02_CINE_PARIS/RENDERS/1.png", title: "Cine Paris" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/02_CINE_PARIS/RENDERS/2.png", title: "Cine Paris" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/02_CINE_PARIS/RENDERS/3.png", title: "Cine Paris" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/02_CINE_PARIS/RENDERS/4.png", title: "Cine Paris" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/02_CINE_PARIS/RENDERS/5.jpg", title: "Cine Paris" },
    ],
  },
  { clickable: true,
    src: "/SITE_PROJECTS/01_RESIDENTIAL/01_VATOS/VATOS_2.jpg", 
    title: "Vatos", 
    redirect: "Vatos",
    category: 'Residential',
    description: ['Vatos_desc'],
    location: 'Vatos_corfu',
    info: 'Vatos_corfu_info',
    images: [
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/01_VATOS/VATOS_1.jpg", title: "Vatos" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/01_VATOS/VATOS_2.jpg", title: "Vatos" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/01_VATOS/VATOS_3.jpg", title: "Vatos" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/01_VATOS/VATOS_4.jpg", title: "Vatos" },
    ],
   },
   { clickable: true,
    src: "/SITE_PROJECTS/01_RESIDENTIAL/02_VILLA_WING/VILLA_WING_1.jpg", 
    title: "Villa Wing", 
    redirect: "Villa_wing",
    category: 'Residential',
    description: ['Villa_wing'],
    location: 'Pentati_Corfu',
    info: 'VIlla_wing_info',
    images: [
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/02_VILLA_WING/VILLA_WING_1.jpg", title: "Villa_wing" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/02_VILLA_WING/VILLA_WING_2.jpg", title: "Villa_wing" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/02_VILLA_WING/VILLA_WING_3.jpg", title: "Villa_wing" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/02_VILLA_WING/VILLA_WING_4.jpg", title: "Villa_wing" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/02_VILLA_WING/VILLA_WING_5.jpg", title: "Villa_wing" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/02_VILLA_WING/VILLA_WING_6.jpg", title: "Villa_wing" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/02_VILLA_WING/VILLA_WING_7.jpg", title: "Villa_wing" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/02_VILLA_WING/VILLA_WING_8.jpg", title: "Villa_wing" }
    ],
   },
   { clickable: true,
    src: "/SITE_PROJECTS/01_RESIDENTIAL/03_VILLA_MIES/VILLA_MIES_1.jpg", 
    title: "Villa Mies", 
    redirect: "Villa_mies",
    category: 'Residential',
    description: ['Villa_mies'],
    location: 'Pentati_Corfu',
    info: 'Pentati_corfu_info',
    images: [
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/03_VILLA_MIES/VILLA_MIES_1.jpg", title: "VILLA_MIES" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/03_VILLA_MIES/VILLA_MIES_2.jpg", title: "VILLA_MIES" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/03_VILLA_MIES/VILLA_MIES_3.jpg", title: "VILLA_MIES" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/03_VILLA_MIES/VILLA_MIES_4.jpg", title: "VILLA_MIES" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/03_VILLA_MIES/VILLA_MIES_5.jpg", title: "VILLA_MIES" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/03_VILLA_MIES/VILLA_MIES_6.jpg", title: "VILLA_MIES" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/03_VILLA_MIES/VILLA_MIES_7.jpg", title: "VILLA_MIES" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/03_VILLA_MIES/VILLA_MIES_8.jpg", title: "VILLA_MIES" }
    ],
   },
   { clickable: true,
    src: "/SITE_PROJECTS/01_RESIDENTIAL/04_VILLA_ROUND/VILLA_ROUND_1.jpg", 
    title: "Villa Round", 
    category: 'Residential',
    redirect: "Villa_round",
    description: ['Villa_round'],
    location: 'Pentati_Corfu',
    info: 'Pentati_corfu_info',
    images: [
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/04_VILLA_ROUND/VILLA_ROUND_1.jpg", title: "VILLA_ROUND" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/04_VILLA_ROUND/VILLA_ROUND_2.jpg", title: "VILLA_ROUND" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/04_VILLA_ROUND/VILLA_ROUND_3.jpg", title: "VILLA_ROUND" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/04_VILLA_ROUND/VILLA_ROUND_5.jpg", title: "VILLA_ROUND" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/04_VILLA_ROUND/VILLA_ROUND_6.jpg", title: "VILLA_ROUND" }
    ],
   },
   { clickable: true,
    src: "/SITE_PROJECTS/01_RESIDENTIAL/05_VILLA_KORAKIANA/VILLA_KORAKIANA_1.jpg", 
    title: "Korakiana", 
    redirect: "Villa_korakiana",
    description: ['Korakiana_desc'],
    category: 'Residential',
    location: 'Pentati_Corfu',
    info: 'Pentati_corfu_info',
    images: [
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/05_VILLA_KORAKIANA/VILLA_KORAKIANA_1.jpg", title: "VILLA_KORAKIANA" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/05_VILLA_KORAKIANA/VILLA_KORAKIANA_2.jpg", title: "VILLA_KORAKIANA" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/05_VILLA_KORAKIANA/VILLA_KORAKIANA_3.jpg", title: "VILLA_KORAKIANA" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/05_VILLA_KORAKIANA/VILLA_KORAKIANA_4.jpg", title: "VILLA_KORAKIANA" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/05_VILLA_KORAKIANA/VILLA_KORAKIANA_5.jpg", title: "VILLA_KORAKIANA" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/05_VILLA_KORAKIANA/VILLA_KORAKIANA_6.jpg", title: "VILLA_KORAKIANA" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/05_VILLA_KORAKIANA/VILLA_KORAKIANA_7.jpg", title: "VILLA_KORAKIANA" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/05_VILLA_KORAKIANA/VILLA_KORAKIANA_8.jpg", title: "VILLA_KORAKIANA" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/05_VILLA_KORAKIANA/VILLA_KORAKIANA_9.jpg", title: "VILLA_KORAKIANA" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/05_VILLA_KORAKIANA/VILLA_KORAKIANA_10.jpg", title: "VILLA_KORAKIANA" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/05_VILLA_KORAKIANA/VILLA_KORAKIANA_11.jpg", title: "VILLA_KORAKIANA" }
    ],
   },
   { clickable: true,
    src: "/SITE_PROJECTS/01_RESIDENTIAL/06_VILLA_GLYFADA/VILLA_GLYFADA_1.jpg", 
    title: "Glyfada", 
    redirect: "VILLA_GLYFADA",
    description: ['Glyfada_desc'],
    category: 'Residential',
    location: 'Pentati_Corfu',
    info: 'Pentati_corfu_info',
    images: [
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/06_VILLA_GLYFADA/VILLA_GLYFADA_1.jpg", title: "VILLA_GLYFADA" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/06_VILLA_GLYFADA/VILLA_GLYFADA_2.jpg", title: "VILLA_GLYFADA" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/06_VILLA_GLYFADA/VILLA_GLYFADA_3.jpg", title: "VILLA_GLYFADA" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/06_VILLA_GLYFADA/VILLA_GLYFADA_4.jpg", title: "VILLA_GLYFADA" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/06_VILLA_GLYFADA/VILLA_GLYFADA_5.jpg", title: "VILLA_GLYFADA" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/06_VILLA_GLYFADA/VILLA_GLYFADA_7.jpg", title: "VILLA_GLYFADA" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/06_VILLA_GLYFADA/VILLA_GLYFADA_8.jpg", title: "VILLA_GLYFADA" }
    ],
   },   
   { clickable: true,
    src: "farmhouse.png",
    title: "Corfu Farmhouse",
    redirect: "Corfu_Farmhouse",
    description: ['Corfu_Farmhouse'],
    location: 'Solari_corfu',
    category: 'Residential',
    info: 'Solari_corfu_info',
    images: [
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/07_CORFU_FARMHOUSE/CORFU_FARMHOUSE_1.jpg", title: "Corfu Farmhouse" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/07_CORFU_FARMHOUSE/CORFU_FARMHOUSE_2.jpg", title: "Corfu Farmhouse" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/07_CORFU_FARMHOUSE/CORFU_FARMHOUSE_4.jpg", title: "Corfu Farmhouse" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/07_CORFU_FARMHOUSE/CORFU_FARMHOUSE_3.jpg", title: "Corfu Farmhouse" },
    ],
   },
   { src: "lux_kitchen.jpg",
     title: "Concept Villa 01", 
     clickable: true, 
     category: 'Residential',
     redirect: "Concept_Villa_01",
     description: ['Concept_villa_01'],
     location: 'Kommeno_corfu',
    info: 'Kommeno_corfu_info',
    images: [
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/08_CONCEPT_VILLA_01/CONCEPT_VILLA_01_1.jpg", title: "Concept_Villa_01" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/08_CONCEPT_VILLA_01/CONCEPT_VILLA_01_2.jpg", title: "Concept_Villa_01" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/08_CONCEPT_VILLA_01/CONCEPT_VILLA_01_4.jpg", title: "Concept_Villa_01" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/08_CONCEPT_VILLA_01/CONCEPT_VILLA_01_3.jpg", title: "Concept_Villa_01" },
    ]
    
   },
   { 
    src: "/SITE_PROJECTS/01_RESIDENTIAL/09_CONCEPT_VILLA_02/CONCEPT_VILLA_02_01.jpg",
    title: "Concept Villa 02",
    clickable: true,
    category: 'Residential',
    redirect: "Concept_Villa_02",
    description: ['Concept_villa_02'],
    location: 'Kommeno_corfu',
    info: 'Kommeno_corfu_info',
    images: [
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/09_CONCEPT_VILLA_02/CONCEPT_VILLA_02_01.jpg", title: "Concept_Villa_02" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/09_CONCEPT_VILLA_02/CONCEPT_VILLA_02_02.jpg", title: "Concept_Villa_02" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/09_CONCEPT_VILLA_02/CONCEPT_VILLA_02_03.jpg", title: "Concept_Villa_02" },
    ]
   },
   { clickable: true,
    src: "/SITE_PROJECTS/01_RESIDENTIAL/10_PAXOI/PAXOI_6.jpg", 
    title: "Paxoi", 
    redirect: "PAXOI",
    category: 'Residential',
    description: ['Paxoi_desc1','Paxoi_desc2'],
    location: 'Dendiatika_paxoi',
    info: 'Dendiatika_paxoi_info',
    images: [
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/10_PAXOI/PAXOI_1.gif", title: "PAXOI" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/10_PAXOI/PAXOI_2.jpg", title: "PAXOI" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/10_PAXOI/PAXOI_3.jpg", title: "PAXOI" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/10_PAXOI/PAXOI_4.jpg", title: "PAXOI" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/10_PAXOI/PAXOI_5.jpg", title: "PAXOI" },
      { src: "/SITE_PROJECTS/01_RESIDENTIAL/10_PAXOI/PAXOI_6.jpg", title: "PAXOI" }
    ],
   },
   { clickable: true,
    src: "/SITE_PROJECTS/02_HOSPITALITY/01_CORFU_ATTIC_APARTMENTS/CORFU_ATTIC_1.jpg", 
    title: "Corfu Attic Apartments", 
    redirect: "CORFU_ATTIC",
    description: ['corfu_attic'],
    location: 'Old_town_corfu',
    info: 'Old_town_corfu_info',
    category: 'Hospitality',
    images: [
      { src: "/SITE_PROJECTS/02_HOSPITALITY/01_CORFU_ATTIC_APARTMENTS/CORFU_ATTIC_1.jpg", title: "CORFU_ATTIC" },
      { src: "/SITE_PROJECTS/02_HOSPITALITY/01_CORFU_ATTIC_APARTMENTS/CORFU_ATTIC_2.jpg", title: "CORFU_ATTIC" },
      { src: "/SITE_PROJECTS/02_HOSPITALITY/01_CORFU_ATTIC_APARTMENTS/CORFU_ATTIC_3.jpg", title: "CORFU_ATTIC" },
      { src: "/SITE_PROJECTS/02_HOSPITALITY/01_CORFU_ATTIC_APARTMENTS/CORFU_ATTIC_4.jpg", title: "CORFU_ATTIC" },
      { src: "/SITE_PROJECTS/02_HOSPITALITY/01_CORFU_ATTIC_APARTMENTS/CORFU_ATTIC_5.jpg", title: "CORFU_ATTIC" },
      { src: "/SITE_PROJECTS/02_HOSPITALITY/01_CORFU_ATTIC_APARTMENTS/CORFU_ATTIC_6.jpg", title: "CORFU_ATTIC" },
      { src: "/SITE_PROJECTS/02_HOSPITALITY/01_CORFU_ATTIC_APARTMENTS/CORFU_ATTIC_7.jpg", title: "CORFU_ATTIC" }
    ],
   },
   { clickable: true,
    src: "/SITE_PROJECTS/02_HOSPITALITY/02_OLD_TOWN_BOUTIQUE_HOTEL/OLD_TOWN_1.jpg", 
    title: "Old Town Boutique Hotel", 
    redirect: "OLD_TOWN",
    description: ['old_town'],
    category: 'Hospitality',
    location: 'Old_town_corfu',
    info: 'Old_town_corfu_info',
    images: [
      { src: "/SITE_PROJECTS/02_HOSPITALITY/02_OLD_TOWN_BOUTIQUE_HOTEL/OLD_TOWN_1.jpg", title: "OLD_TOWN" },
      { src: "/SITE_PROJECTS/02_HOSPITALITY/02_OLD_TOWN_BOUTIQUE_HOTEL/OLD_TOWN_2.jpg", title: "OLD_TOWN" },
      { src: "/SITE_PROJECTS/02_HOSPITALITY/02_OLD_TOWN_BOUTIQUE_HOTEL/OLD_TOWN_3.jpg", title: "OLD_TOWN" },
      { src: "/SITE_PROJECTS/02_HOSPITALITY/02_OLD_TOWN_BOUTIQUE_HOTEL/OLD_TOWN_4.jpg", title: "OLD_TOWN" }
    ],
   },
   { clickable: true,
    src:  "/SITE_PROJECTS/03_COMMERCIAL/03_DENTIST_OFFICE/1.jpg",
    title: "Dentist's office",
    category: 'Commercial',
    redirect: "DENTIST_OFFICE",
    description: [`dentist_office` ],
    location: 'Neos_kosmos',
    info: 'Neos_kosmos_info',
    photoBy: "Spatial Architecture + Design",
    images: [
      { src: "/SITE_PROJECTS/03_COMMERCIAL/03_DENTIST_OFFICE/1.jpg", title: "DENTIST_OFFICE" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/03_DENTIST_OFFICE/2.jpg", title: "DENTIST_OFFICE" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/03_DENTIST_OFFICE/3.jpg", title: "DENTIST_OFFICE" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/03_DENTIST_OFFICE/4.jpg", title: "DENTIST_OFFICE" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/03_DENTIST_OFFICE/5.jpg", title: "DENTIST_OFFICE" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/03_DENTIST_OFFICE/6.jpg", title: "DENTIST_OFFICE" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/03_DENTIST_OFFICE/7.jpg", title: "DENTIST_OFFICE" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/03_DENTIST_OFFICE/8.jpg", title: "DENTIST_OFFICE" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/03_DENTIST_OFFICE/9.jpg", title: "DENTIST_OFFICE" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/03_DENTIST_OFFICE/10.jpg", title: "DENTIST_OFFICE" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/03_DENTIST_OFFICE/11.jpg", title: "DENTIST_OFFICE" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/03_DENTIST_OFFICE/12.jpg", title: "DENTIST_OFFICE" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/03_DENTIST_OFFICE/13.jpg", title: "DENTIST_OFFICE" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/03_DENTIST_OFFICE/14.jpg", title: "DENTIST_OFFICE" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/03_DENTIST_OFFICE/15.jpg", title: "DENTIST_OFFICE" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/03_DENTIST_OFFICE/16.jpg", title: "DENTIST_OFFICE" }
    ],
  },
  { clickable: true,
    src:  "/SITE_PROJECTS/03_COMMERCIAL/04_HEARTCORE_GYM/HEARTCORE_2.jpg",
    title: "Pilates studio",
    redirect: "pilates_studio",
    category: 'Commercial',
    description: [`pilates_studio` ],
    location: 'Ilisia_athens',
    info: 'Ilisia_athens_info',
    images: [
      { src: "/SITE_PROJECTS/03_COMMERCIAL/04_HEARTCORE_GYM/HEARTCORE_1.jpg", title: "heartcore_gym" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/04_HEARTCORE_GYM/HEARTCORE_2.jpg", title: "heartcore_gym" },
      { src: "/SITE_PROJECTS/03_COMMERCIAL/04_HEARTCORE_GYM/HEARTCORE_3.jpg", title: "heartcore_gym" }
    ],
  }
];

export default projectsData;
