import React, { useEffect, useRef, useState} from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import projectsData from "./util/ProjectData";
import { useTranslation } from 'react-i18next';




function Projects() {
  useEffect(() => {
  }, []);
  const { t, i18n } = useTranslation();


  const handleLinkClick = (e,canNavigate) => {
    if (!canNavigate) {
      e.preventDefault();
    }
  }
  const [activeCategory, setActiveCategory] = useState("all");

  const categories = [
    { id: "all", label: t("All") },
    { id: "Residential", label: t("Residential") },
    { id: "Commercial", label: t("Commercial") },
    { id: "Hospitality", label: t("Hospitality") },
  ];

  const [displayedProjects, setDisplayedProjects] = useState(projectsData);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [containerHeight, setContainerHeight] = useState('auto');
  const containerRef = useRef(null);



  const handleCategoryChange = (category) => {
    // Store current container height before transition
    if (containerRef.current) {
      setContainerHeight(`${containerRef.current.offsetHeight}px`);
    }
    
    setIsTransitioning(true);
    setActiveCategory(category.id);
    
    // After fade out animation completes, update the displayed projects
    setTimeout(() => {
      const filteredProjects = projectsData.filter(
        project => category === "all" || project.category === category
      );
      setDisplayedProjects(filteredProjects);
      setIsTransitioning(false);
      
      // Wait for new content to render, then adjust height
      requestAnimationFrame(() => {
        if (containerRef.current) {
          if( category !== "Hospitality" ){
            const newHeight = `${containerRef.current.scrollHeight}px`;
            setContainerHeight(newHeight);
          }
     
          
          // Reset to auto after transition
          setTimeout(() => {
            setContainerHeight('auto');
          }, 300);
        }
      });
    }, 500);
  };


  return (
    <>
      <Helmet>
        <title>Our Projects - Spatial Architecture</title>
        <meta
          name="description"
          content="Explore the diverse range of projects completed by Spatial Architecture. From residential to commercial, discover how we blend innovation with practicality."
        />
        <meta
          property="og:title"
          content="Our Projects - Spatial Architecture"
        />
        <meta
          property="og:description"
          content="Explore the diverse range of projects completed by Spatial Architecture. From residential to commercial, discover how we blend innovation with practicality."
        />
        <meta
          property="og:image"
          content="https://github.com/gdimitropoulos/public-photos/blob/main/projects.png"
        />
        <meta
          property="og:url"
          content="https://spatialarchitecture.gr/projects"
        />
      </Helmet>
      <Container className="flex-grow-1">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Nav
            variant="tabs"
            className="mb-4 custom-tabs"
            activeKey={activeCategory}
            onSelect={handleCategoryChange}
          >
            {categories.map((category) => (
              <Nav.Item key={category.id}>
                <Nav.Link
                  eventKey={category.id}
                  className={activeCategory === category.id ? "active" : ""}
                >
                  {category.label}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </div>

        <div 
          ref={containerRef} 
          className="projects-container"
          style={{ minHeight: containerHeight }}
        >
          <Row className="justify-content-center">
            {displayedProjects.map((image, index) => (
              <Col 
                xs={12} 
                md={4} 
                className={`project-col mb-4 ${isTransitioning ? 'fade-out' : ''}`} 
                key={index}
              >
                <Link 
                  onClick={(e) => handleLinkClick(e, image.clickable)} 
                  to={`/project/${image.redirect}`} 
                  className="image-container"
                >
                  <img
                    src={image.src}
                    alt={image.title}
                    className="project-image"
                  />
                  <div className="image-title">
                    <p style={{ fontSize: "25px" }}>{t(image.title)}</p>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </>
  );
}

export default Projects;
