import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

const FullScreenShadowModal = ({ showModal, onClose, selectedImage, images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  

  useEffect(() => {
    // Find the index of the selected image when modal opens
    if (selectedImage) {
      const index = images.findIndex(img => img === selectedImage);
      setCurrentIndex(index);
    }
  }, [selectedImage, images]);

  const handlePrevious = () => {
    setCurrentIndex(prev => (prev === 0 ? images.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrentIndex(prev => (prev === images.length - 1 ? 0 : prev + 1));
  };

  // Handle keyboard navigation
  const handleKeyPress = (e) => {
    if (e.key === 'ArrowLeft') {
      handlePrevious();
    } else if (e.key === 'ArrowRight') {
      handleNext();
    } else if (e.key === 'Escape') {
      onClose();
    }
  };
  useEffect(() => {
    if (showModal) {
      window.addEventListener('keydown', handleKeyPress);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [showModal]);

  return (
    <Modal 
      show={showModal} 
      onHide={onClose}
      size="lg"
      centered
      className="image-modal"
    >
      <Modal.Header closeButton className="border-0 p-4"   style={{ height: '4rem' }} >
        <span className="ms-auto text-muted">
          {currentIndex + 1} / {images.length}
        </span>
      </Modal.Header>
      <Modal.Body  className="p-0 position-relative"   style={{
              height: '80vh', 
              width: '100%'}}
              >
      <div 
            className="d-flex justify-content-center align-items-center" 
            style={{
              padding: 0, 
              margin: 0 ,
              width: '100%',
              height: '100%'
            }}
          >
            <img
              src={images[currentIndex].src}
              alt="Fullscreen view"
              style={{
                objectFit: 'contain', 
                display: 'block',
                width: '100%',
                maxHeight: '77vh'
              }}
  />
          
          {/* Navigation buttons */}
          <button
            onClick={handlePrevious}
            className="custom-nav-button position-absolute start-0 top-55 translate-middle-y bg-transparent border-0 d-flex justify-content-center align-items-center hover:bg-black/20 transition-colors rounded-end"
            style={{
              fontSize: '4rem',
              width: '80px',
              height: '80px',
              cursor: 'pointer',
              opacity: '0.7',
              color: 'white', // Ensures the arrow is visible
              textShadow: '0px 0px 10px rgba(0, 0, 0, 0.7)', // Shadow around the arrow
            }}
            aria-label="Previous image"
          >
              <span className="arrow-text">‹</span>
          </button>
          <button
            onClick={handleNext}
            className="custom-nav-button position-absolute end-0 top-55 translate-middle-y bg-transparent border-0 d-flex justify-content-center align-items-center hover:bg-black/20 transition-colors rounded-start"
            style={{
              fontSize: '4rem',
              width: '80px',
              height: '80px',
              cursor: 'pointer',
              opacity: '0.7',
              color: 'white', // Ensures the arrow is visible
              textShadow: '0px 0px 10px rgba(0, 0, 0, 0.7)', // Shadow around the arrow
            }}
            aria-label="Next image"
          >
              <span className="arrow-text">›</span>

          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FullScreenShadowModal;
